import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'TimeSheets';
  storedTheme: string = localStorage.getItem('theme-color');
  static role = localStorage.getItem("role");
  static roleValid: boolean[];

  constructor() { }

  ngOnInit() {
    AppComponent.roleValid = [false, false, false, false];
    this.storedTheme = localStorage.getItem('theme-color');
    switch (AppComponent.role) {
      case "EMPLOYEE": {
        AppComponent.roleValid[0] = true;
        break;
      }
      case "ADMIN": {
        AppComponent.roleValid[1] = true;
        break;
      }
      case "MANAGER": {
        AppComponent.roleValid[2] = true;
        break;
      }
      case "PM": {
        AppComponent.roleValid[3] = true;
        break;
      }
      default: {
      }
    }

    // //console.log("role is  ", AppComponent.roleValid);

  }

  // setTheme(theme: string) {
  //   localStorage.setItem('theme-color', theme);
  //   this.storedTheme = localStorage.getItem('theme-color');
  // }
}
