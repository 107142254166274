import { CreateEmployee } from './createEmployee.model';
import { CreateProject } from './createProject.model';
import { Skill } from './skill.model'

export class CreateTask {
  billableHour: number;
  createdBy: string;
  createdTimeStamp: string;
  description: string;
  employees: Array<CreateEmployee>;
  endDate: string;
  endedOn: string;
  id: any;
  taskCode: string;
  name: string;
  nonBillableHour: number;
  project: CreateProject;
  // skills: Array<Skill>;
  skills: any;
  startDate: string;
  startedOn: string;
  status: string;
  type: string;
  updatedAt: string;
  updatedTimeStamp: string;
  usedHour: any;
  usedNBHours:any;
  selected: boolean;

  constructor() {
    this.billableHour = null;
    this.createdBy = null;
    this.createdTimeStamp = null;
    this.description = null;
    this.employees = new Array<CreateEmployee>();
    this.endDate = null;
    this.endedOn = null;
    this.id = null;
    this.taskCode = null;
    this.name = null;
    this.nonBillableHour = null;
    this.project = new CreateProject();
    this.skills = null;
    this.startDate = null;
    this.startedOn = null;
    this.status = null;
    this.type = null;
    this.updatedAt = null;
    this.updatedTimeStamp = null;
    this.usedHour = null;
    this.selected = false;
  }
}