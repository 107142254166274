import { Injectable } from '@angular/core';
import { environment as env } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogserviceService {

  public env = env;

  eipAddress = env.ip + '/employee';
  sipAddress = env.ip + '/timesheet';
  // sipAddress = '192.168.1.8:9094/timesheet'
  aipAddress = env.ip + '/auth';
  // aipAddress = 'DESKTOP-PEIE49U:9094/auth';
  eprotocol = 'http://';
  IP_ADDRESS = this.eipAddress;

  ENV_API: string = this.eprotocol + this.eipAddress;
  AENV_API: string = this.eprotocol + this.aipAddress;
  SENV_API: string = this.eprotocol + this.sipAddress;





  Login_API: string = this.AENV_API + '/auth/v1/api/user/login';
  get_permissionsByToken_API: string = this.AENV_API + '/auth/v1/api/user/validatetoken';
  get_AllRoles_API: string = this.AENV_API + '/auth/v1/api/role/get';
  get_Allpermissions_API: string = this.AENV_API + '/auth/v1/api/permission/get';
  get_UserByEmpId_API: string = this.AENV_API + '/auth/v1/api/user/get/';
  update_AllRoles_API: string = this.AENV_API + '/auth/v1/api/role/assignpermission';
  changePassword: string = this.AENV_API + '/auth/v1/api/user/change-password';
  forgot_password: string = this.AENV_API + '/auth/v1/api/user/forgot-password';


  skill_creation_API: string = this.ENV_API + '/employee/v1/api/skill/save';
  designation_creation_API: string = this.ENV_API + '/employee/v1/api/designation/save';
  Employee_creation_API: string = this.ENV_API + '/employee/v1/api/employee/save';
  get_designations_API: string = this.ENV_API + '/employee/v1/api/designation/get';
  get_skills_API: string = this.ENV_API + '/employee/v1/api/skill/get';
  get_AllEmployees_API: string = this.ENV_API + '/employee/v1/api/employee/get';
  get_EMPLOYEEBYUSERNAME_API: string = this.ENV_API + '/employee/v1/api/employee/get/email/';
  get_EmployeeLastId_API: string = this.ENV_API + '/employee/v1/api/employee/get/lastEmpId';
  get_EmployeeAgeCriteria_API: string = this.ENV_API + '/employee/v1/api/employee/get/ageCriteria';
  get_OrgFoundationDate_API: string = this.ENV_API + '/employee/v1/api/employee/get/foundationDate';
  get_SingleEmployee_API: string = this.ENV_API + '/employee/v1/api/employee/get/';
  get_ACTIVE_Employees_API: string = this.ENV_API + '/employee/v1/api/employee/get/status';
  get_AllCountries_API: string = this.ENV_API + '/employee/v1/api/address/country/';
  get_AllStates_API: string = this.ENV_API + '/employee/v1/api/address/state/';
  // get_AllRepotingManagers_API: string = this.ENV_API + '/employee/v1/api/employee/get';
  update_Employee_API: string = this.ENV_API + '/employee/v1/api/employee/update'

  Client_creation_API: string = this.SENV_API + '/timesheet/v1/api/client/save';
  get_AllClients_API: string = this.SENV_API + '/timesheet/v1/api/client/get';
  get_ClientCode_API: string = this.SENV_API + '/timesheet/v1/api/client/get/clientCode';
  get_SingleClient_API: string = this.SENV_API + '/timesheet/v1/api/client/get/';
 remove_POC_client_API: string = this.SENV_API + '/timesheet/v1/api/client/update/removePoc/';
  // get_ClientProjects_API: string = this.SENV_API + '/timesheet/v1/api/client/';
  Project_creation_API: string = this.SENV_API + '/timesheet/v1/api/project/save';
  add_Emplyees_To_Project:string=this.SENV_API+'/timesheet/v1/api/project/addEmployees'
  get_SingleProject_API: string = this.SENV_API + '/timesheet/v1/api/project/get/';
  get_employee_count_by_employeeId:string=this.SENV_API+'/timesheet/v1/api/get/employee/projects/'
  get_AllProjects_API: string = this.SENV_API + '/timesheet/v1/api/project/get';
  get_ProjectCode_API: string = this.SENV_API + '/timesheet/v1/api/project/get/projectCode';
  update_Project_API: string = this.SENV_API + '/timesheet/v1/api/project/update/';
  Task_creation_API: string = this.SENV_API + '/timesheet/v1/api/task/save';
  get_AllTasks_API: string = this.SENV_API + '/timesheet/v1/api/task/get';
  get_Manager_Project_List: string = this.SENV_API + '/timesheet/v1/api/project/getProjectList/';
  get_Timesheet_API: string = this.SENV_API + '/timesheet/v1/api/timesheet/get';
  get_Timesheet_By_id: string = this.SENV_API + '/timesheet/v1/api/timesheet/get/'; //id will appended from the timesheet-service
  put_Timesheet_API: string = this.SENV_API + '/timesheet/v1/api/timesheet/update';
  // get_Timesheet_Audit_API: string = this.SENV_API + 
  put_Task_Comment_API: string = this.SENV_API + '/timesheet/v1/api/task/update/comments';
  // put_Timesheet_Comment_API: string = this.SENV_API + '/timesheet/v1/api/task/update/comments';
  get_AllLocations_API: string = this.SENV_API + '/timesheet/v1/api/location/get';
  get_AllEmployeeRoles_API: string = this.SENV_API + '/timesheet/v1/api/employeerole/get';
  delete_TaskCard_API: string = this.SENV_API + '/timesheet/v1/api/taskcard/delete';
  get_TaskCards_API: string = this.SENV_API + '/timesheet/v1/api/taskcard/get';
  get_AllInvoices_API: string = this.SENV_API + '/timesheet/v1/api/invoice/get';
  put_TaskCardDetails_API: string = this.SENV_API + '/timesheet/v1/api/taskcarddetail/update';
  get_TaskCardAuditList_API: string = this.SENV_API + '/timesheet/v1/api/taskcard/getAudit'
  get_TaskCardDetails_API: string = this.SENV_API + '/timesheet/v1/api/taskcarddetail/get';
  get_RateCards_API: string = this.SENV_API + '/timesheet/v1/api/ratecard/get';
  put_RateCards_API: string = this.SENV_API + '/timesheet/v1/api/ratecard/update';
  put_Invoice_API: string = this.SENV_API + '/timesheet/v1/api/invoice/save';
  put_client_API: string = this.SENV_API + '/timesheet/v1/api/client/update';

  get_AllIndustries_API: string = this.SENV_API + '/timesheet/v1/api/client/industry/get';
  get_AllFocusAreas_API: string = this.SENV_API + '/timesheet/v1/api/client/focusarea/get';
  Industry_Creation_API: string = this.SENV_API + '/timesheet/v1/api/client/industry/save';
  FocusArea_Creation_API: string = this.SENV_API + '/timesheet/v1/api/client/focusarea/save';
  update_client_API: string = this.SENV_API + '/timesheet/v1/api/client/update/';
  get_ProjectStatuses_API: string = this.SENV_API + '/timesheet/v1/api/project/get/statuses';
  get_SignleTask_API: string = this.SENV_API + '/timesheet/v1/api/task/get/';
  update_SingleTask_API: string = this.SENV_API + '/timesheet/v1/api/task/update/';
  remove_Single_Employee_From_task: string = this.SENV_API + '/timesheet/v1/api/task/removeEmployee';
  get_tasks_of_employee_from_Project: string = this.SENV_API + '/timesheet/v1/api/task/get/activeTaskIdForAnEmployeeWithProjectId';
  remove_employee_from_project: string = this.SENV_API + '/timesheet/v1/api/project/deleteEmployee'
  clone_Timsheet_API: string = this.SENV_API + '/timesheet/v1/api/timesheet/clone';
  update_AllTasks_API: string = this.SENV_API + '/timesheet/v1/api/task/update';
  update_AllProjects_API: string = this.SENV_API + '/timesheet/v1/api/project/update';
  get_ProjectEndDate_API: string = this.SENV_API + '/timesheet/v1/api/project/get/enddate';
  get_taskEndDate_API: string = this.SENV_API + '/timesheet/v1/api/task/get/enddate';
  holiday_calendar_API: string = this.SENV_API + '/timesheet/v1/api/holiday/Save';
  save_calendar_API: string = this.SENV_API + '/timesheet/v1/api/holiday/save';
  get_calendar_API: string = this.SENV_API + '/timesheet/v1/api/holiday/getByYear';
  get_calendarYears_API: string = this.SENV_API + '/timesheet/v1/api/holiday/getAllYears';

  get_createExcel_API: string = this.SENV_API + '/timesheet/v1/api/holiday/createExcel';

  get_empTask_API: string = this.SENV_API + '/timesheet/v1/api/task/emptask/'
  get_pendingts_API: string = this.SENV_API + '/timesheet/v1/api/task/pendingts/'
  get_OldTimesheets_API: string = this.SENV_API + '/timesheet/v1/api/timesheet/dashboard/'
  get_weekProjects_API: string = this.SENV_API + '/timesheet/v1/api/timesheet/weekprojects/'
  get_TaskCardsBill_API: string = this.SENV_API + '/timesheet/v1/api/taskcard/billnonbill';

  //sowPath: string = "C:\Users\RedShift\Desktop\Files\SOW";
  sowPath: string = "C:\Users\mkris\Downloads\time sheet\time sheet\timesheet";
  PSS_UType: string;
  token: string;
  constructor() { }
}
