import { POC } from './poc.model';
import { Address } from './address.model';
import { FocusArea } from './focusArea.model';
import { IdClass } from './IdClass.model';

export class CreateClient {
    id: number;
    about: string;
    address: Address;
    billingAddress: Address;
    clientCode: string;
    industry: IdClass;
    name: string;
    futureFocus: string;
    // pocs: Array<POC>;
    pocs: any;
    // focusAreas: Array<FocusArea>;
    focusAreas: any;
    status: string;
    url: string;
    selected: boolean;

    constructor() {
        this.id = null;
        this.about = null;
        this.address = new Address();
        this.billingAddress = new Address();
        this.clientCode = null;
        this.industry = new IdClass();
        this.name = null;
        this.futureFocus = null;
        this.focusAreas = new Array<FocusArea>();
        this.status = null;
        this.url = null;
        this.selected= false;
    }
}