export class Country {
    id: number;
    countryCode: string;
    name: string;
    phoneCode: number;

    constructor(){
        this.id= null;
        this.countryCode = null;
        this.name = null;
        this.phoneCode = null;
    }
}