import { CreateClient } from './createClient.model';
import { CreateEmployee } from './createEmployee.model'
import { RateCard } from './rateCard.model';

export class CreateProject {
        id: any;
        client: CreateClient;
        description: string;
        employees: Array<CreateEmployee>;
        manager: CreateEmployee;
        endDate: string;
        endedOn: string;
        estimatedCost: number;
        estimatedDays: number;
        projectCode: string;
        name: string;
        rateCard: RateCard;
        startDate: string;
        startedOn: string;
        status: string;
        isDefault: boolean;
        isAutoApprovePMTimesheets: boolean;
        type: string;
        selected: boolean;
        createdBy: string;
        default: boolean;
        constructor() {
                this.id = null;
                this.client = new CreateClient();
                this.description = null;
                this.employees = new Array<CreateEmployee>();
                this.manager = new CreateEmployee();
                this.endDate = null;
                this.endedOn = null;
                this.estimatedCost = null;
                this.estimatedDays = null;
                this.projectCode = null;
                this.name = null;
                this.rateCard = new RateCard();
                this.startDate = null;
                this.startedOn = null;
                this.status = null;
                this.isDefault = false;
                this.type = null;
                this.selected = false;
                this.default = false;
                this.isAutoApprovePMTimesheets = true;
                this.createdBy = null;
        }
}