import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LogserviceService } from 'src/app/logservice.service';
import { CreateTask } from 'src/app/shared/models/createTask.model';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TaskServiceService {

  taskObject: CreateTask;

  constructor(private http: HttpClient, private data: LogserviceService) {
    this.taskObject = new CreateTask();
  }
  private errorMessage: string;

  setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  getErrorMessage(): string {
    return this.errorMessage;
  }
  empTask(): Observable<any> {
    let queryObject;
    queryObject = { id: localStorage.getItem("employeeId") };
    let queryParams = new HttpParams({
      fromObject: queryObject
    });
    return this.http.get(this.data.get_empTask_API, { params: queryParams });
  }


  pendingTs(): Observable<any> {
    let queryObject;
    queryObject = { id: localStorage.getItem("employeeId") };
    let queryParams = new HttpParams({
      fromObject: queryObject
    });
    return this.http.get(this.data.get_pendingts_API, { params: queryParams });
  }


  newTaskCreation(formData: CreateTask): Observable<any> {
    return this.http.post(this.data.Task_creation_API, formData);
  }

  getAllTasks(employeeId?: number, status?: string, projectId?: string, limits?: number, page?: number, search?: string,sDate?: string,eDate?: string): Observable<any> {
    let queryObject;
    if (search && employeeId) {
      queryObject = { employeeId: employeeId, search: search, limits: limits, page: page }
    }
    else if (employeeId) {
      queryObject = { employeeId: employeeId, limits: limits, page: page }
    }
    else if (status) {
      queryObject = { projectId: projectId, status: status, employeeId: localStorage.getItem("employeeId"),sDate:sDate,eDate:eDate };
    } else if (projectId) {
      queryObject = { projectId: projectId, limits: limits, page: page }
    } else {
      queryObject = { limits: limits, page: page }
    }
    let queryParams = new HttpParams({
      fromObject: queryObject
    });
    return this.http.get(this.data.get_AllTasks_API, { params: queryParams });
  }
  getAllProjectTasks(status?: string, projectId?: string, limits?: number, page?: number, search?: string): Observable<any> {
    let queryObject;
    if (search && projectId) {
      queryObject = { search: search, projectId: projectId, limits: limits, page: page }
    } else {
      queryObject = { limits: limits, page: page }
    }
    let queryParams = new HttpParams({
      fromObject: queryObject
    });
    return this.http.get(this.data.get_AllTasks_API, { params: queryParams });
  }

  getTaskById(taskNum): Observable<any> {
    return this.http.get(this.data.get_SignleTask_API + taskNum);
  }

  updateSingleTask(task): Observable<any> {
    return this.http.put(this.data.update_SingleTask_API + task.id, task);
  }


  removeEmployeeFromTask(task_id: number, employee_id: number): Observable<any> {
    return this.http.post(`${this.data.remove_Single_Employee_From_task}/${task_id}/${employee_id}`, {});
  }


  updateMultipleTasks(tasks, status?: string): Observable<any> {
    let queryObject;
    if (status)
      queryObject = { status: status };
    let queryParams = new HttpParams({
      fromObject: queryObject
    });

    return this.http.put(this.data.update_AllTasks_API, tasks, { params: queryParams });
  }


  taskEndDate(startDate, estimatedHours): Observable<any> {
    let queryObject = { startDate: startDate, estimatedHours: estimatedHours };
    let queryParams = new HttpParams({
      fromObject: queryObject
    });
    return this.http.get(this.data.get_taskEndDate_API, { params: queryParams });
  }
}
