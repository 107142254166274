import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LogserviceService } from 'src/app/logservice.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  constructor(private http: HttpClient, private logService: LogserviceService) { }

  Authentication(username: string, password: string) {
    let json = { username_email: username, password: password };
    return this.http.post(this.logService.Login_API, json);
  }

  permissions(): Observable<any> {
    return this.http.get(this.logService.get_permissionsByToken_API);
  }

  getAllPermissions(): Observable<any> {
    return this.http.get(this.logService.get_Allpermissions_API);
  }

  getUserByEmpId(empId: any): Observable<any> {
    return this.http.get(this.logService.get_UserByEmpId_API + empId);
  }

  getRoles(): Observable<any> {
    return this.http.get(this.logService.get_AllRoles_API);
  }

  assignPermissions(roles): Observable<any> {
    return this.http.post(this.logService.update_AllRoles_API, roles);
  }
  changePassword(oldPassword: string, newPassword: string): Observable<boolean> {
    const headers = new HttpHeaders().set('Authorization', `Bearer ${this.logService.token}`);
    const requestBody = { oldPassword, newPassword };
    //console.log("Change Password End point ")
    return this.http.post<boolean>(this.logService.changePassword, requestBody, { headers });
  }
  sendForgotPasswordCode(email: string): Observable<boolean> {
    //console.log("forgot password end point");
    const requestBody = { email }
    return this.http.put<boolean>(this.logService.forgot_password, requestBody);

  }
}
