import { Injectable, Injector } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpHeaders, HttpResponse, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError, pipe } from "rxjs";
import { map, catchError, tap, skip } from "rxjs/operators";
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LoaderServiceService } from '../shared/loader/loader-service.service';
import { TimesheetsComponent } from "../layout/timesheets/timesheets.component";
import { TaskServiceService } from "./tasks/task-service.service";

@Injectable()
export class Interceptor implements HttpInterceptor {
    constructor(public toastr: ToastrService, public router: Router, private loaderService: LoaderServiceService,private tservice: TaskServiceService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        this.loaderService.show();

        if (localStorage.getItem('access-token') != null) {
            const token = localStorage.getItem('access-token');
            const headers = new HttpHeaders().set("Authorization", token);
            headers.set("Access-Control-Allow-Origin", "*");
            const AuthRequest = request.clone({ headers: headers });
            return next.handle(AuthRequest)
                .pipe(
                    map((event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {
                            // //console.log('event--->>>', event);
                            // //console.log("success event");
                            this.loaderService.hide();
                        }
                        return event;
                    }, (err: any) => {
                        this.loaderService.hide();
                    }),
                    catchError((error: HttpErrorResponse) => {
                        // //console.log(error);
                        this.loaderService.hide();
                        // let status = error.status;
                        // //console.log(status);
                        if(error.error.message)
                            {
                                if (error.error.message === "Duplicate task cards found!") {
                                    this.toastr.info(error.error.message);
                                }
                                
                                
                               else if ((error.error.message).startsWith("Employee with email") || (error.error.message).startsWith("Client code already"))
                                    {
                                        this.toastr.info(error.error.message);
                                    }
                                    else{
                                        this.toastr.error(error.error.message, 'Error');
                                    }        
                            }
                        else
                        {
                            if (!(error.error.apierror.message.startsWith("Please submit following Pending TimeSheet(s)") || 
                            error.error.apierror.message.startsWith("Unable to Submit TimeSheet without Entries") || 
                            error.error.apierror.message.startsWith("The Task Start Date") || 
                            error.error.apierror.message.startsWith("Invalid actual start date") || 
                            error.error.apierror.message.startsWith("Invalid actual end date") || 
                            error.error.apierror.message.startsWith("Time entry for future dates") || 
                            error.error.apierror.message.includes("Duplicate Record found") || 
                            error.error.apierror.message.endsWith("NB' Already Exist") || 
                            error.error.apierror.message.endsWith("B' Already Exist"))) {
                      
                        //   //console.log('error', error);
                          this.toastr.error(error.error.apierror.message, 'Error');
                      
                      } else {
                          if (!error.error.apierror.message.endsWith("NB' Already Exist") && 
                              !error.error.apierror.message.endsWith("B' Already Exist") && !(error.error.apierror.message.startsWith("Please submit following Pending TimeSheet(s)"))) {
                      
                            //   //console.log('error', error);
                              this.toastr.info(error.error.apierror.message);
                          }
                      }
                      

                        }
                        if (error.error.message == "unauthorized access") {
                            // localStorage.clear();
                            // this.router.navigate(['/login']);
                        }
                        return throwError(error);
                    }));
        } else {
            return next.handle(request)
                .pipe(
                    map((event: HttpEvent<any>) => {
                        // //console.log("inside pipe");
                        if (event instanceof HttpResponse) {
                            // //console.log('event--->>>', event);
                            // //console.log("success event");
                            this.loaderService.hide();
                        }
                        return event;
                    }, (err: any) => {
                        this.loaderService.hide();
                        // //console.log("dnsdvnsnvn");
                    }),
                    catchError((error: HttpErrorResponse) => {
                        this.loaderService.hide();
                        let status = error.status;
                        // //console.log(error);
                        // //console.log('error.apierror.status', error.error.apierror.status);
                        if(error.error.apierror.message.startsWith('You are in-active please contact'))
                            {
                                this.toastr.info('INACTIVE User');
                            }
                                else{
                                    this.toastr.error('User Name or password', 'Invalid');
                                }
                        if (error.error.message == "unauthorized access") {
                            // localStorage.clear();
                            // this.router.navigate(['/login']);
                        }
                        return throwError(error);
                    }))
        }
    }
}