import { Designation } from './designation.model';
import { IdClass } from './IdClass.model';

export class ReportingManager{
    id: number;
    firstName: string;
    lastName: string;
    dob: string;
    emailId: string;
    employeeCode: string;
    phoneNumber: string;
    gender: string;
    designation: Designation;
    role: IdClass;

    constructor(){
        this.id = null;
        this.firstName = null;
        this.lastName = null;
        this.dob = null;
        this.emailId = null;
        this.employeeCode = null;
        this.phoneNumber = null;
        this.gender = null;
        this.designation = new Designation();
        this.role = new IdClass();
    }
}