import { Designation } from './designation.model';
import { Skill } from './skill.model';
import { Address } from './address.model';
import { IdClass } from './IdClass.model';
import { ReportingManager } from './reportingManager.model';

export class CreateEmployee {
    id: number;
    //employeeId: number;
    firstName: string;
    lastName: string;
    dob: string;
    emailId: string;
    employeeCode: string;
    phoneNumber: string;
    gender: string;
    maritalStatus: string;
    anniversaryDate: string;
    designation: Designation;
    reportingManager: ReportingManager;
    joiningDate: string;
    resignationDate: string;
    skills: any; //to accept string array
    address: Address;
    role: IdClass;
    lastWorkingDate: string;
    selected: boolean;

    constructor() {
        this.id = null;
        //this.employeeId = null;
        this.firstName = null;
        this.lastName = null;
        this.dob = null;
        this.emailId = null;
        this.employeeCode = null;
        this.phoneNumber = null;
        this.gender = null;
        this.maritalStatus = null;
        this.anniversaryDate = null;
        this.designation = new Designation();
        this.reportingManager = new ReportingManager();
        this.joiningDate = null;
        // this.skills=null;
        this.address = new Address();
        this.role = new IdClass();
        this.lastWorkingDate = null;
        this.selected = true;
    }

}

