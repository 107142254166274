import { Country } from './country.model';
import { State } from './state.model';


export class Address{
    id: number;
    addressLine1: string;
    addressLine2: string;
    country: Country;
    state: State;
    zipCode: string;

    // constructor(
    // public address1: string,
    // public address2: string,
    // public country: string,
    // public state: string,
    // public zipcode: string
    // ){}

    constructor(){
        this.id = null;
        this.addressLine1 = null;
        this.addressLine2 = null
        this.country = new Country();
        this.state = new State();
        this.zipCode = null;
    }
}